import { Tooltip, IconButton, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

import { IAsset } from 'types/asset';

type AssetMenuProps = {
  selectedAsset: IAsset | null;
  toggleOpenAddProduct: () => void;
  toggleOpenDeleteProduct: () => void;
  downloadExcel: () => void;
  downloadExcelStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  downloadExcelErrorMessage: string | null;
};

export const AssetMenu = ({
  selectedAsset,
  toggleOpenAddProduct,
  toggleOpenDeleteProduct,
  downloadExcel,
  downloadExcelStatus,
  downloadExcelErrorMessage,
}: AssetMenuProps) => (
  <Box sx={{ mx: 1 }}>
    {selectedAsset ? (
      <>
        <Tooltip title="編集">
          <IconButton aria-label="銘柄を編集" onClick={toggleOpenAddProduct}>
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="削除">
          <IconButton aria-label="銘柄を削除" onClick={toggleOpenDeleteProduct}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip title="新規追加">
          <IconButton
            aria-label="銘柄を新規銘柄"
            onClick={toggleOpenAddProduct}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </Tooltip>
        {/* <AddProductModal modalState={openAddProduct} closeModal={() => setOpenAddProduct(false)} /> */}
      </>
    )}
    <Tooltip title="Excelファイルをダウンロード (売却済み銘柄も含まれます)">
      {downloadExcelStatus === 'pending' ? (
        <IconButton>
          <CircularProgress size="1.685rem" />
        </IconButton>
      ) : (
        <IconButton aria-label="download" onClick={downloadExcel}>
          <GetAppIcon color="primary" />
        </IconButton>
      )}
    </Tooltip>
    {downloadExcelStatus === 'rejected' && downloadExcelErrorMessage !== null ? (
      <div>{downloadExcelErrorMessage}</div>
    ) : null}
  </Box>
);
