import React, { useState, useEffect, useRef } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

interface INumericalFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  error: boolean;
  helperText: string;
  onFocus?: () => void;
  onBlur?: () => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;
}

interface ISelectProps {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  error: boolean;
  helperText: string;
}

const useNumericalState = (initialValue: number | null) => {
  const [num, setNum] = useState<number | null>(initialValue);
  const [editableText, setEditableText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [_fracDigitsm, _setFracDigits] = useState<number>(0);
  // _fracDigits < 0: 123
  // _fracDigits === 0: 123.
  // _fracDigits === 1: 123.1
  const inputRef = useRef<HTMLInputElement>(null);
  const showError = () => {
    setError(true);
    setHelperText('入力必須です');
  };

  const _commafy = (num: number): string => {
    const numString = num.toString();
    if (!numString.includes('.')) {
      return num.toLocaleString('ja-JP');
    }
    const fracDigits = numString.split('.')[1].length;
    return num.toLocaleString('ja-JP', { minimumFractionDigits: fracDigits });
  };
  useEffect(() => {
    if (num !== null) {
      setEditableText(num.toString());
    } else {
      setEditableText('');
    }
  }, [num]);

  useEffect(() => {
    if (error) {
      inputRef?.current?.focus();
    }
  }, [error, inputRef]);

  const showNumValue = (): string => {
    if (num === null) {
      return '';
    }
    return _commafy(num);
  };

  const parseOnBlur = () => {
    setEditMode(false);
    const _num = parseFloat(editableText);
    if (Number.isNaN(_num)) {
      setEditableText('');
      return;
    }
    setNum(_num);
    setEditableText(_num.toString());
  };
  const fieldProps = {
    error: error,
    helperText: helperText,
    value: editMode ? editableText : showNumValue(),
    onFocus: () => {
      setEditMode(true);
    },
    onBlur: parseOnBlur,
    onChange: (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = (e.target.value as string).replace(/[¥￥,]/g, '').trim();
      // const value = (e.target.value as string).replaceAll(',', '')
      if (value === '') {
        setEditableText('');
        setNum(null);
      }
      const pattern = /^\d+(\.\d*)?$/;
      const isNum = pattern.test(value);
      if (!isNum) {
        return;
      }
      const valueFloat = parseFloat(value);
      if (Number.isNaN(valueFloat)) {
        return;
      }
      setEditableText(value);
      setNum(valueFloat);
      setError(false);
      setHelperText('');
    },
    inputRef: inputRef,
  } as INumericalFieldProps;
  const _reset = () => {
    setNum(initialValue);
    setEditableText(initialValue?.toString() || '');
    setError(false);
    setHelperText('');
  };
  return [
    num,
    setNum,
    { props: fieldProps, showError: showError, reset: _reset },
  ] as const;
};

const useSelectState = (initialValue: string) => {
  const [choice, setChoice] = useState<string>(initialValue);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const showError = () => {
    setError(true);
    setHelperText('選択必須です');
  };

  const fieldProps = {
    error: error,
    helperText: helperText,
    value: choice,
    onChange: (e: React.ChangeEvent<{ value: unknown }>) => {
      setChoice(e.target.value as string);
      setError(false);
      setHelperText('');
    },
  } as ISelectProps;
  const _reset = () => {
    setChoice(initialValue);
    setError(false);
    setHelperText('');
  };
  return [
    choice,
    setChoice,
    { props: fieldProps, showError: showError, reset: _reset },
  ] as const;
};

interface ITextFieldProps {
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const useTextState = (initialValue: string) => {
  const [_text, _setText] = useState<string>(initialValue);
  const [_error, _setError] = useState<boolean>(false);
  const [_helperText, _setHelperText] = useState<string>('');
  const _onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    _setText(event.target.value);
    _setError(false);
    _setHelperText('');
  };
  const _props = {
    value: _text,
    onChange: _onChange,
    error: _error,
    helperText: _helperText,
  };
  const _showError = () => {
    _setError(true);
    _setHelperText('入力必須です');
  };
  const _reset = () => {
    _setText(initialValue);
    _setError(false);
    _setHelperText('');
  };
  return [
    _text,
    _setText,
    { props: _props, showError: _showError, reset: _reset },
  ] as const;
};

export { useNumericalState, useSelectState, useTextState };
export type { INumericalFieldProps, ITextFieldProps, ISelectProps };
