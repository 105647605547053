import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  styled,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { IAsset, Order } from 'types/asset';
import { columnOrder } from './columnOrder';
import { FrozenColumnTableCell } from './FrozenColumnTableCell';

interface SortableTableHeadProps {
  buildSorter: (property: keyof IAsset) => void;
  order: Order;
  orderBy: keyof IAsset;
  detailVisible: boolean;
}

const TableHeaderText = styled('span')({
  fontWeight: 'bold',
  whiteSpace: 'pre',
});

const FrozenHeadCell = styled(FrozenColumnTableCell)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 200,
  },
  zIndex: theme.zIndex.appBar + 2, // should be larger than FrozenColumnTableCell
}));

export const SortableTableHead = React.memo(
  ({ buildSorter, order, orderBy, detailVisible }: SortableTableHeadProps) => (
    <TableHead>
      <TableRow>
        <FrozenHeadCell
          key={columnOrder[0].key}
          align="left"
          sortDirection={orderBy === columnOrder[0].key ? order : false}
          sx={{ backgroundColor: '#fafafa', width: 2 }}
        >
          <TableSortLabel
            active={orderBy === columnOrder[0].key}
            direction={orderBy === columnOrder[0].key ? order : 'asc'}
            onClick={() => buildSorter(columnOrder[0].key)}
          >
            <TableHeaderText>{columnOrder[0].label}</TableHeaderText>
            {orderBy === columnOrder[0].key ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </FrozenHeadCell>
        {columnOrder
          .slice(1)
          .filter(c => !c.isDetail || detailVisible)
          .map(c => (
            <TableCell
              key={c.key}
              align={c.align}
              sortDirection={orderBy === c.key ? order : false}
              sx={{ backgroundColor: '#fafafa', py: 0.5, px: 1 }}
            >
              <TableSortLabel
                active={orderBy === c.key}
                direction={orderBy === c.key ? order : 'asc'}
                onClick={() => buildSorter(c.key)}
              >
                <TableHeaderText>{c.label}</TableHeaderText>
                {orderBy === c.key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  )
);
