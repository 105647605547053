import { Order } from 'types';

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  secondOrderBy: keyof T
) {
  // firebase.firestore.Timestamp is also compaarble with >, < (but not for ==, ===)
  // null or undefined is handled as the lowest value
  const av = a[orderBy];
  const bv = b[orderBy];
  if (av == null && bv != null) {
    return 1;
  }
  if (av != null && bv == null) {
    return -1;
  }
  const productTypes = [
    '債券',
    '株式',
    'ETF',
    '投資信託',
    'ファンド',
    'キャッシュ',
    '不動産',
    'オルタナティブ',
    'その他',
  ];
  if (
    orderBy === 'productType' &&
    typeof av === 'string' &&
    productTypes.indexOf(av) >= 0 &&
    typeof bv === 'string' &&
    productTypes.indexOf(bv) >= 0
  ) {
    if (productTypes.indexOf(av) > productTypes.indexOf(bv)) {
      return 1;
    }
    if (productTypes.indexOf(av) < productTypes.indexOf(bv)) {
      return -1;
    }
  }
  if (bv < av) {
    return -1;
  }
  if (bv > av) {
    return 1;
  }

  const productNameA = a[secondOrderBy] as string;
  const productNameB = b[secondOrderBy] as string;
  return productNameA.localeCompare(productNameB);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  secondOrderBy: Key | 'productName' = 'productName'
): (
  a: { [key in Key | 'productName']?: number | string | null | Date },
  b: { [key in Key | 'productName']?: number | string | null | Date }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, secondOrderBy)
    : (a, b) => -descendingComparator(a, b, orderBy, secondOrderBy);
}
