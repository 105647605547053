import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  styled,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { IAsset, Order } from 'types';


interface SortableTableHeadProps {
  buildSorter: (property: keyof IAsset) => void;
  order: Order;
  orderBy: keyof IAsset;
  detailVisible: boolean;
}

const TableHeaderText = styled('span')({
  fontWeight: 'bold',
  whiteSpace: 'pre',
});

export const SortableTableHead = React.memo(
  ({ buildSorter, order, orderBy, detailVisible }: SortableTableHeadProps) => (
    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
      <TableRow>
        <TableCell
          key="productName"
          align="left"
          sortDirection={orderBy === 'productName' ? order : false}
          sx={{
            backgroundColor: '#fafafa',
            position: 'sticky',
            left: 0,
            whiteSpace: 'unset',
            padding: 1,
            fontSize: 14,
          }}
          rowSpan={2}
        >
          <TableSortLabel
            active={orderBy === 'productName'}
            direction={orderBy === 'productName' ? order : 'asc'}
            onClick={() => buildSorter('productName')}
          >
            <TableHeaderText>銘柄名</TableHeaderText>
            {orderBy === 'productName' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="purchasePrice"
          align="right"
          sortDirection={orderBy === 'purchasePrice' ? order : false}
          sx={{ backgroundColor: '#fafafa', py: 0.5, px: 1, fontSize: 14 }}
        >
          <TableSortLabel
            active={orderBy === 'purchasePrice'}
            direction={orderBy === 'purchasePrice' ? order : 'asc'}
            onClick={() => buildSorter('purchasePrice')}
          >
            <TableHeaderText>買付単価</TableHeaderText>
            {orderBy === 'purchasePrice' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="marketValueDomestic"
          align="right"
          sortDirection={orderBy === 'marketValueDomestic' ? order : false}
          sx={{ backgroundColor: '#fafafa', py: 0.5, px: 1, fontSize: 14 }}
        >
          <TableSortLabel
            active={orderBy === 'marketValueDomestic'}
            direction={orderBy === 'marketValueDomestic' ? order : 'asc'}
            onClick={() => buildSorter('marketValueDomestic')}
          >
            <TableHeaderText>
              評価額<span style={{ fontSize: 10, marginLeft: 1 }}>(円)</span>
            </TableHeaderText>
            {orderBy === 'marketValueDomestic' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          key="marketPrice"
          align="right"
          sortDirection={orderBy === 'marketPrice' ? order : false}
          sx={{ backgroundColor: '#fafafa', py: 0.5, px: 1, fontSize: 14 }}
        >
          <TableSortLabel
            active={orderBy === 'marketPrice'}
            direction={orderBy === 'marketPrice' ? order : 'asc'}
            onClick={() => buildSorter('marketPrice')}
          >
            <TableHeaderText>時価(評価)</TableHeaderText>
            {orderBy === 'marketPrice' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="PLDomestic"
          align="right"
          sortDirection={orderBy === 'PLDomestic' ? order : false}
          sx={{ backgroundColor: '#fafafa', py: 0.5, px: 1, fontSize: 14 }}
        >
          <TableSortLabel
            active={orderBy === 'PLDomestic'}
            direction={orderBy === 'PLDomestic' ? order : 'asc'}
            onClick={() => buildSorter('PLDomestic')}
          >
            <TableHeaderText>
              評価損益<span style={{ fontSize: 10, marginLeft: 1 }}>(円)</span>
            </TableHeaderText>
            {orderBy === 'PLDomestic' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
);
